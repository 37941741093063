import React from 'react';
import { FacebookProvider, Like, Comments } from 'react-facebook';
import Config from '../../../config';

const FacebookSection = (props) => {
  const { pageCanonicalUrl } = props;

  return (
    <FacebookProvider appId={Config.facebookAppID}>
      <Like href={pageCanonicalUrl} colorScheme="dark" showFaces share />
      <Comments href={pageCanonicalUrl} />
    </FacebookProvider>
  );
};

export default FacebookSection;
